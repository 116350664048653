:root {
  --blue: #00008B;
  --purple: #8A2BE2;
  --light-purple: #9370DB;
}

.cta-fullscreen-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  background: #ffffff7a;
}

.cta-content {
  max-width: 80ch;
  width: 100%;
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.cta-main-title {
  font-size: 4rem;
  margin-bottom: 2rem;
  font-weight: 500;
  background: linear-gradient(45deg, var(--blue), var(--purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.3;
}

.cta-description {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #270d57;
  max-width: 600px;
}

.cta-video-wrapper {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 2rem;
  aspect-ratio: 16 / 9;
  padding: 10px;
}

.video-border {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.video-border::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: conic-gradient(
    from 0deg,
    transparent 0deg,
    rgba(138, 43, 226, 0.6) 60deg,
    transparent 120deg
  );
  animation: rotateBorder 4s linear infinite;
  z-index: 1;
}

.video-border::after {
  content: '';
  position: absolute;
  inset: 5px;
  background: white;
  border-radius: 0.9rem;
  z-index: 2;
}

.cta-video-frame {
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border: none;
  z-index: 3;
  border-radius: 0.8rem;
  margin: 5px;
}

.cta-subtitle {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 700;
  color: var(--blue);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.cta-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.cta-button {
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  isolation: isolate;
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 600;
  border-radius: 9999rem;
  background: var(--blue);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 48px;
  overflow: hidden;
  transition: 200ms ease-out;
  box-shadow: 0 10px 20px rgba(0, 0, 139, 0.2);
  margin: 0 auto;
  margin-bottom: 4rem;
}

.cta-button > .backdrop {
  z-index: 1;
  transition: 150ms ease-out;
}

.cta-button > .overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  background: var(--purple);
  position: absolute;
  inset: 0;
  z-index: 5;
  transform: translateY(calc(100% + 1px));
  transition: 200ms ease-out;
}

.cta-button:is(:hover, :focus) {
  background: color-mix(in oklab, var(--blue), black 40%);
  transition: 250ms ease-out;
}

.cta-button:is(:hover, :focus) > .backdrop {
  scale: 0.8;
  opacity: 0.5;
}

.cta-button:is(:hover, :focus) > .overlay {
  transform: translateY(0);
}

@keyframes rotateBorder {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Agregamos un efecto de brillo */
.video-border::before {
  box-shadow: 0 0 15px 5px rgba(138, 43, 226, 0.3);
}

/* Añadimos un efecto de pulso */
@keyframes pulse {
  0%, 100% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.02) rotate(180deg);
  }
}

.video-border::before {
  animation: rotateBorder 4s linear infinite, pulse 8s ease-in-out infinite;
}

@media (max-width: 768px) {
  .cta-main-title {
    font-size: 2.5rem;
  }

  .cta-description {
    font-size: 1.1rem;
    margin-bottom: 3rem;
  }

  .cta-subtitle {
    font-size: 1.5rem;
  }

  .cta-button {
    padding: 12px 36px;
    font-size: 1rem;
  }
}

.wave-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.cta-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 1rem;
}

.video-placeholder {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Mantiene relación de aspecto 16:9 */
  background: #000;
  cursor: pointer;
}

.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 68px;
  height: 48px;
  background-color: rgba(0,0,0,0.7);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
}

.video-link:hover .play-button {
  background-color: red;
}
