.second-cta-section {
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  min-height: 60vh;
  padding: 8rem 2rem;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Efecto de partículas en el fondo */
.second-cta-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(circle at 25% 25%, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    radial-gradient(circle at 75% 75%, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 50px 50px;
  animation: particleFloat 20s linear infinite;
}

.second-cta-content {
  max-width: 90ch;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
  background: #ffffff;
  padding: 3rem;
  border-radius: 2rem;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
}

.second-cta-title {
  font-size: clamp(2.5rem, 5vw, 4.5rem);
  margin-bottom: 3rem;
  font-weight: 800;
  line-height: 1.2;
  background: linear-gradient(45deg, #60a5fa, #a855f7, #ec4899);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 30px rgba(96, 165, 250, 0.5);
  position: relative;
}

.second-cta-title::after {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 4px;
  background: linear-gradient(90deg, #60a5fa, #a855f7);
  border-radius: 2px;
}

.second-cta-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
}

.second-cta-button {
  appearance: none;
  background: linear-gradient(45deg, #3b82f6, #8b5cf6);
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: 9999rem;
  position: relative;
  padding: 1.25rem 3rem;
  transition: all 0.3s ease;
  transform-style: preserve-3d;
  box-shadow: 
    0 10px 30px -10px rgba(59, 130, 246, 0.5),
    0 0 0 2px rgba(255, 255, 255, 0.1) inset;
}

.second-cta-button::before {
  content: '';
  position: absolute;
  inset: -4px;
  border-radius: inherit;
  background: linear-gradient(45deg, #60a5fa, #a855f7, #ec4899);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.second-cta-button:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 
    0 20px 40px -20px rgba(59, 130, 246, 0.7),
    0 0 0 2px rgba(255, 255, 255, 0.2) inset;
}

.second-cta-button:hover::before {
  opacity: 1;
}

.second-cta-button > .backdrop,
.second-cta-button > .overlay {
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.second-cta-button > .overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #8b5cf6, #ec4899);
  border-radius: inherit;
  transform: translateY(100%);
  opacity: 0;
}

.second-cta-button:hover > .backdrop {
  transform: scale(0.9);
  opacity: 0;
}

.second-cta-button:hover > .overlay {
  transform: translateY(0);
  opacity: 1;
}

@keyframes particleFloat {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

/* Efectos de brillo */
.second-cta-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, rgba(96, 165, 250, 0.1), transparent 70%);
  animation: glowPulse 4s ease-in-out infinite;
}

@keyframes glowPulse {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* Media queries para responsividad */
@media (max-width: 768px) {
  .second-cta-section {
    padding: 6rem 1.5rem;
  }

  .second-cta-content {
    padding: 2rem 1.5rem;
  }

  .second-cta-title {
    margin-bottom: 2rem;
  }

  .second-cta-button {
    font-size: 1.1rem;
    padding: 1rem 2rem;
  }
}

@media (max-width: 480px) {
  .second-cta-section {
    padding: 4rem 1rem;
  }

  .second-cta-content {
    padding: 1.5rem 1rem;
  }

  .second-cta-button {
    width: 100%;
    max-width: 300px;
    font-size: 1rem;
    padding: 0.875rem 1.5rem;
  }
} 