/* Contenedor principal del footer */
.footer-container {
    background: linear-gradient(135deg, #f0f4f8 0%, #d9e2ec 100%); /* Degradado de gris claro */
    position: relative;
    padding: 50px 20px;
    color: #333;
    font-family: 'Inter', sans-serif;
    text-align: center;
}

/* Ola en el footer */
.footer-wave {
    background-size: cover;
    height: 100px;
    position: absolute;
    top: -90px;
    left: 0;
    right: 0;
    z-index: 1;
}

/* Contenido del footer */
.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    z-index: 2;
    position: relative;
}

/* Columnas del footer */
.footer-column {
    margin: 20px;
    text-align: left;
}

.footer-column h3 {
    font-size: 1.5rem;
    color: #320d5e;
    margin-bottom: 15px;
}

.footer-column ul {
    list-style: none;
    padding: 0;
}

.footer-column ul li {
    margin-bottom: 10px;
}

.footer-column ul li a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-column ul li a:hover {
    color: #320d5e; /* Color al pasar el mouse */
}

/* Logo */
.footer-logo {
    width: 150px;
    height: auto;
    display: inline-block;
    margin-top: 10px;
}

/* Íconos de redes sociales */
.footer-icons {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.footer-icon {
    width: 40px;
    height: 40px;
    color: #333;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
}

.footer-icon:hover {
    color: #320d5e;
    transform: scale(1.1);
}

/* Parte inferior del footer */
.footer-bottom {
    margin-top: 50px;
    font-size: 0.9rem;
    color: #555;
    z-index: 2;
    position: relative;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-column {
        margin: 20px 0;
    }

    .footer-icons {
        justify-content: center;
    }
}
