@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
$fondo: #ecebf5;
$color-primario: #120424;
$color-secundario: #320d5e;
$color-terciario: #ffffff;
$color-cuaternario: #03c5c3;
$color-quintario: #017a79;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

html {
    scroll-behavior: smooth;
  }

::-webkit-scrollbar {
    display: none;
}

/* styles.css */
body {
    line-height: 1.6;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}

header {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1001;
    width: 100%;
    padding: 0px 0; /* Reducir el padding vertical */
  }
  
  .nav_logo {
    background-image: url('img/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    height: 60px; /* Establecer una altura fija */
    margin: 0 auto; /* Centrar horizontalmente */
    
    @media screen and (max-width: 768px) {
      width: 150px;
      height: 45px; /* Ajustar la altura para pantallas más pequeñas */
    }
  }
  



.marginAuto {
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #290083;
    text-align: center;

}

.marginAuto2 {
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #290083;
    text-align: center;
}

.marginAuto2 h1 {
    text-align: center; /* Asegura el centrado del texto específicamente */
}


section {
    padding: 0.2rem 0;
}

h1,
h2 {
    font-weight: 300;
    color: #333;
}

a {
    color: #333;
    text-decoration: none;
}

a:hover {
    color: #555;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    margin: 40px;
    padding: 0px;
    margin-top: 100px;
    flex-direction: column;

    .title {
        font-size: 40px;
        font-weight: 600;
        color: #290083;
        margin-bottom: 20px;
        margin: 10px;
        padding: 1rem;

        @media screen and (max-width: 1070px) {
            font-size: 30px;
            margin: 0px;
            padding: 0.2rem;
        }
    }
}

.imgCard {
    width: 130px;
    height: 130px;
    border-radius: 30%;
    object-fit: scale-down;
    margin-bottom: 15px;
    background-color: #fff;
}

.imgCard2 {
    width: 130px;
    height: 130px;
    border-radius: 30%;
    object-fit: cover;
    margin-bottom: 15px;
    background-color: #fff;
}

.cta-button {
    display: inline-block;
    background: $color-cuaternario;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    margin-top: 1rem;
    align-self: flex-start;
    text-decoration: none;
    transition: all 0.5s ease-in-out 0s;
    font-size: 1.4rem;
    margin-left: 60px;

    @media screen and (max-width: 1070px) {
        margin-left: 0px;
        font-size: 1rem;
        align-self: center;
    }
}

.cta-button:hover {
    background: $color-secundario;
    color: #ffffff;
}

#home {
    background-color: $fondo;
    background-size: cover;
    background-attachment: fixed; 
    min-height: 100vh;
    width: 100%;
    color: white;
    transition: background-color 0.5s ease-in-out 0s;
    box-sizing: border-box;
    text-align: center;
    padding: 2rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#home h1,
#home p,
#home .centerDiv {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#home h1 {
    font-size: 3rem;
    color: #320d5e;
    max-width: none;
    margin: 0 auto 20px;
    text-align: center;

    @media screen and (max-width: 1070px) {
        font-size: 1.8rem;
    }
}

#home p {
    font-size: 1.5rem;
    color: $color-secundario;
    margin-bottom: 50px;
    text-align: center;
}

#home .centerDiv {
    text-align: center;
    margin: 30px auto;
    max-width: 600px;

    .iaEscribiendo.act,
    .iaEscribiendo.des {
        margin-bottom: 30px;
    }
}

#pricing {
    text-align: center;
}


#beneficios {
    text-align: center;
    background-color: #18A6BF;
    padding: 1rem;
    color: #ffffff;
}

#beneficios h1 {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 10px;
    color:white;
}

.facts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.fact {
    background-color: #7b72c165; /* o el color que prefieras */
    margin: 1rem;
    max-width: 250px;
    min-width: 250px;

    padding: 1rem;
    border-bottom: 5px solid #ffffff;
    flex: 0 1 30%; /* los bloques ocuparán cerca de un tercio de la anchura del contenedor, con la capacidad de encogerse pero no de crecer */
}

.big-number {
    font-size: 5rem;
    font-weight: 400;
    margin: 0;
}

#beneficios p {
    margin: 0;
    max-width: 700px;
}

/* Estilos para móviles */
@media (max-width: 768px) {
    .facts-container {
        flex-direction: column;
        align-items: center;
    }

    .fact {
        flex-basis: auto; /* permite que los bloques se expandan al 100% del contenedor en dispositivos móviles */
    }
}




.content {
    line-height: 1.6;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #0d865e;
}

footer {
    background: #333;
    color: #fff;
    text-align: center;
    padding: 1rem 0;
    margin-top: 20px;
    font-size: 1.2rem;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;

}

.ecosistema {
    background-color: $color-primario;
    color: #fff;
    padding: 1rem;
    margin: 20px;
    font-size: 1.2rem;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.flex {
    display: flex;
    flex-direction: column;
    padding: 0rem;
}

.tarjetas {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;
    margin: 1rem;

    @media screen and (max-width: 1070px) {
        flex-direction: column;
    }
}

.tarjeta1 {
    background-color: #03c5c3;
    border-radius: 10px;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    color: #ffffff;
    width: 50vh;

    h2 {
        color: #ffffff;
        font-weight: 700;
    }

    @media screen and (max-width: 1070px) {
        width: auto;
    }

    &:hover {
        background-color: #17aec2;
        transition: all 0.5s ease;
        transform: translateY(-10px);
    }
}

.tarjeta2 {
    background-color: #4b66ad;
    border-radius: 10px;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    color: #ffffff;
    width: 50vh;

    h2 {
        color: #ffffff;
        font-weight: 700;
    }

    @media screen and (max-width: 1070px) {
        width: auto;
    }

    &:hover {
        background-color: #5070ff;
        transition: all 0.5s ease;
        transform: translateY(-10px);
    }
}

.tarjeta3 {
    background-color: #b24fb1;
    border-radius: 10px;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    color: #ffffff;
    width: 50vh;

    h2 {
        color: #ffffff;
        font-weight: 700;
    }

    @media screen and (max-width: 1070px) {
        width: auto;
    }

    &:hover {
        background-color: #681c77;
        transition: all 0.5s ease;
        transform: translateY(-10px);
    }
}

.titleEco {
    color: $color-secundario;
    font-weight: 700;
    font-size: 2.4rem;
    margin-bottom: 1rem;
    text-align: center;
}



.startButton {
    background-color: #1a0a35;
    color: #ffffff;
    border-radius: 10px;
    padding: 0.5rem;
    font-size: 0.8rem;
    font-weight: 700;
    width: 100px;
    text-align: center;
    margin-top: 10px;

    @media screen and (max-width: 1070px) {
        width: auto;
    }

    &:hover {
        background-color: $color-secundario;
        cursor: pointer;
    }
}


.contactButton {
    background-color: #5415db;
    color: white;
    border-radius: 8px;
    border: 0px;
    margin: 16px;
    padding: 16px 20px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;

    @media screen and (max-width: 1070px) {
        width: auto;
    }

    &:hover {
        background-color: $color-secundario;
        cursor: pointer;
    }
}

.caracteristica {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px;
    border-radius: 10px;
    text-align: left;
    padding: 1rem;
    margin: 1rem;
    color: #000000;
    width: 50vh;

    h2 {
        color: #ffffff;
        font-weight: 700;
    }

    @media screen and (max-width: 1070px) {
        width: auto;

    }

    &:hover {
        background-color: #fffae9;
        transition: all 0.5s ease;
        transform: translateY(-10px);
    }
}

.videoFrameWrapper {
    background-image: url('img/compu.png'); /* Asegúrate de que la ruta sea correcta */
    background-size: 52%; /* o 'cover' si prefieres que la imagen cubra completamente el área sin preservar su relación de aspecto */
    background-repeat: no-repeat;
    background-position: center center;
    width: 960px; /* Ancho fijo para pantallas grandes */
    height: 310px; /* La misma altura que tu 'iframe' */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eef7f6;
}

@media (max-width: 1070px) {
    .videoFrameWrapper {
        width: 100vw; /* 100% del ancho de la pantalla */
        background-size: 100% auto; /* Ajusta la imagen de fondo para que ocupe el 100% del nuevo ancho */
        max-width: 100%; /* Asegura que el contenedor no exceda el ancho de la pantalla */
    }
}
  .videoWrapper iframe {
    width: 100%; /* Esto asegurará que el 'iframe' ocupe todo el ancho del contenedor '.videoWrapper' */
    height: 100%; /* Esto asegurará que el 'iframe' ocupe toda la altura del contenedor '.videoWrapper' */
    border: 0; /* Elimina el borde del 'iframe' si hay alguno */
  }
  .contentWrapper {
    display: flex;
    align-items: center;
    flex-direction: row; /* Dirección por defecto para pantallas grandes */
  }
  
  .videoWrapper, .textWrapper {
    padding: 10px; /* Ajustado para no sobre-espaciar en pantallas grandes */
}
  
  .divider {
    height: 70%;
    width: 1px;
    background-color: #e6e2e2;
    margin: 0 20px;
  }
  
  /* Media query para pantallas de menos de 768px de ancho */
  @media (max-width: 1070px) {
    .contentWrapper {
      flex-direction: column; /* Cambia la disposición a columna */
      text-align: center;
    }


    .videoWrapper, .textWrapper {
      padding: 10px; /* Reduce el padding en pantallas pequeñas */
    }
  
    .divider {
      height: 1px; /* Cambia a una línea horizontal */
      width: 80%; /* Ajusta al ancho deseado o usa 'auto' */
      margin: 10px auto; /* Centra la línea horizontalmente y añade un poco de margen vertical */
      text-align: center;
    }
  

  
    .videoPorQueContainer {
      height: auto; /* Permite que el contenedor crezca según el contenido */
      text-align: center;

    }
  }
  
  .whyTitle{
    font-size: 24px;
    color: #1a0a35;
    max-width: 600px;
    margin-top: 15px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    text-align: left;
    margin-bottom: 30px;
}

@media (max-width: 1070px) {
    .whyTitle {
      flex-direction: column; /* Cambia la disposición a columna */
      text-align: center;
      width: auto;

    }

    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

    .videoFrameWrapper {
        position: relative; /* Para posicionar absolutamente el iframe dentro de este contenedor */
        padding-top: 75%; /* Ajusta la relación de aspecto si es necesario para pantallas más pequeñas */
      }
}



  .whySubTitle{
    color: #5415db;
    text-align: left;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-top: 16px;
    margin-bottom: 0;
    font-size: 18px;
    margin-left: 20px;
    line-height: 27px;
  }

  .titleCard{
    color: #3c05a1;
    text-align: left;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-top: 16px;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 27px;
  }



  

  .cursor {
    border-right: 2px solid black; /* Ajusta el color según tu diseño */
    padding-right: 2px; /* Ajusta para alinear correctamente el cursor */
    animation: blinkCursor 0.7s steps(5, start) infinite;
  }
  
  @keyframes blinkCursor {
    from {border-right-color: black;}
    to {border-right-color: transparent;}
  }

  .contenedorImagenCentrada {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; /* Cambiado de 100vh a auto */
    padding: 20px; /* Añade un poco de padding si lo necesitas */
    margin-top: 0px;
}
  
  .imagenCentrada {
    max-width: 100%; /* Esto asegura que la imagen se ajuste al contenedor */
    height: auto; /* Ajusta la altura de la imagen automáticamente basado en el ancho */
  }

  .cardPolitics{
    margin: auto;
    padding: 50px;
  }

