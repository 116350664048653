/* Estilos del contenedor general */
.caracteristicas-container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
  }
  
  /* Estilo del título principal */
  .caracteristicas-title {
    font-size: 3rem;
    font-weight: 700;
    color: #320d5e; /* Color principal personalizado */
    margin-bottom: 50px;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    letter-spacing: 1px;
  }
  
  /* Estilos del contenedor de tarjetas */
  .caracteristicas-tarjetas {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
  
  /* Estilo de cada tarjeta */
  .caracteristicas-card {
    background-color: #f9f9f9;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: calc(33.33% - 30px);
    min-width: 280px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .caracteristicas-card:hover {
    transform: translateY(-10px); /* Efecto de elevación */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Sombra más pronunciada */
  }
  
  /* Estilos para los íconos */
  .caracteristicas-icon {
    width: 50px;
    height: 50px;
    color: #320d5e;
    margin-bottom: 20px;
  }
  
  /* Estilo de los títulos de las tarjetas */
  .caracteristicas-titleCard {
    font-size: 1.5rem;
    color: #320d5e;
    margin-bottom: 10px;
    font-weight: 600;
  }
  
  /* Estilos del texto de las tarjetas */
  .caracteristicas-text {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
  }
  
  /* Responsive: Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    .caracteristicas-tarjetas {
      flex-direction: column;
    }
  
    .caracteristicas-card {
      width: 100%; /* Tarjetas toman el 100% del ancho en pantallas pequeñas */
    }
  }
  
.cta-section {
  background: #f5f5f5; /* Fondo minimalista */
  padding: 60px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  transition: transform 0.3s ease;
}

.cta-section:hover {
  transform: scale(1.02);
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.cta-section p {
  font-size: 1.3rem;
  margin-bottom: 40px;
  line-height: 1.6;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.cta-button {
  --color: #fff;
  --color-hover: #fff;
  --background: #362A89;
  --background-hover: #6D58FF;
  --hover-back: #6D58FF;
  --hover-front: #F6F8FF;
  padding: 15px 30px;
  border-radius: 25px;
  line-height: 24px;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: .02em;
  border: none;
  outline: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  color: var(--color);
  background: var(--background);
  transition: color .2s linear, background .3s linear;
}

.cta-button.alternative {
  --color-hover: #2B3044;
  --background: #362A89;
  --hover-back: #6D58FF;
  --hover-front: #F6F8FF;
}

.cta-button:hover {
  background-color: var(--background-hover);
  color: #6D58FF
}

.cta-button:active {
  transform: translateY(1px);
}

.cta-button div {
  z-index: 1;
  position: relative;
  display: flex;
}

.cta-button span {
  display: block;
  backface-visibility: hidden;
  transform: translateZ(0);
  animation: move .7s linear forwards .18s;
}

.cta-button:not(.simple)::before,
.cta-button:not(.simple)::after {
  content: '';
  position: absolute;
  background: var(--hover-back);
  top: 0;
  left: 0;
  right: 0;
  
  height: 200%;
  border-radius: 40%;
  transform: translateY(50%);
  transition: transform .4s ease-in, border-radius .5s ease;
}

.cta-button:not(.simple)::after {
  background: var(--hover-front);
  transition-duration: .44s;
}

.cta-button.in {
  --name: move;
  --color: var(--color-hover);
  --background: var(--background-hover);
}

.cta-button.in:not(.out)::before,
.cta-button.in:not(.out)::after {
  transform: translateY(0);
  border-radius: 5%;
}

.cta-button.in.out::before {
  transition-delay: .06s;
}

@keyframes move {
  30%, 36% {
    transform: translateY(calc(-6px * var(--move))) translateZ(0) rotate(calc(-13deg * var(--rotate) * var(--part)));
  }
  50% {
    transform: translateY(calc(3px * var(--move))) translateZ(0) rotate(calc(6deg * var(--rotate) * var(--part)));
  }
  70% {
    transform: translateY(calc(-2px * var(--move))) translateZ(0) rotate(calc(-3deg * var(--rotate) * var(--part)));
  }
}

@keyframes move-out {
  30%, 36% {
    transform: translateY(calc(6px * var(--move))) translateZ(0) rotate(calc(13deg * var(--rotate) * var(--part)));
  }
  50% {
    transform: translateY(calc(-3px * var(--move))) translateZ(0) rotate(calc(-6deg * var(--rotate) * var(--part)));
  }
  70% {
    transform: translateY(calc(2px * var(--move))) translateZ(0) rotate(calc(3deg * var(--rotate) * var(--part)));
  }
}

.playful-button {
  --color: #fff;
  --color-hover: #fff;
  --background: #362A89;
  --background-hover: #6D58FF;
  --hover-back: #6D58FF;
  --hover-front: #F6F8FF;
  padding: 15px 30px;
  border-radius: 25px;
  line-height: 24px;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: .02em;
  border: none;
  outline: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  color: var(--color);
  background: var(--background);
  transition: color .2s linear, background .3s linear;
}

.playful-button:hover {
  background-color: var(--background-hover);
  color: #0d0737;
}

.playful-button:active {
  transform: translateY(1px);
}

.playful-button div {
  z-index: 1;
  position: relative;
  display: flex;
}

.playful-button span {
  display: block;
  backface-visibility: hidden;
  transform: translateZ(0);
  animation: move .7s linear forwards .18s;
}

.playful-button:not(.simple)::before,
.playful-button:not(.simple)::after {
  content: '';
  position: absolute;
  background: var(--hover-back);
  top: 0;
  left: 0;
  right: 0;
  height: 200%;
  border-radius: 40%;
  transform: translateY(50%);
  transition: transform .4s ease-in, border-radius .5s ease;
}

.playful-button:not(.simple)::after {
  background: var(--hover-front);
  transition-duration: .44s;
}

.playful-button.in {
  --name: move;
  --color: var(--color-hover);
  --background: var(--background-hover);
}

.playful-button.in:not(.out)::before,
.playful-button.in:not(.out)::after {
  transform: translateY(0);
  border-radius: 5%;
}

.playful-button.in.out::before {
  transition-delay: .06s;
}

@keyframes move {
  30%, 36% {
    transform: translateY(calc(-6px * var(--move))) translateZ(0) rotate(calc(-13deg * var(--rotate) * var(--part)));
  }
  50% {
    transform: translateY(calc(3px * var(--move))) translateZ(0) rotate(calc(6deg * var(--rotate) * var(--part)));
  }
  70% {
    transform: translateY(calc(-2px * var(--move))) translateZ(0) rotate(calc(-3deg * var(--rotate) * var(--part)));
  }
}

@keyframes move-out {
  30%, 36% {
    transform: translateY(calc(6px * var(--move))) translateZ(0) rotate(calc(13deg * var(--rotate) * var(--part)));
  }
  50% {
    transform: translateY(calc(-3px * var(--move))) translateZ(0) rotate(calc(-6deg * var(--rotate) * var(--part)));
  }
  70% {
    transform: translateY(calc(2px * var(--move))) translateZ(0) rotate(calc(3deg * var(--rotate) * var(--part)));
  }
}

.registro-form {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.registro-form div {
  margin-bottom: 15px;
}

.registro-form label {
  display: block;
  margin-bottom: 5px;
}

.registro-form input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.registro-form button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.registro-form button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .registro-form {
    padding: 10px;
  }
}
