/* Contenedor general de la sección */
#howWorks {
    text-align: center;
    background: linear-gradient(135deg, #140f3d 0%, #240b5e 100%); /* Fondo con degradado sutil */
    padding: 3rem;
    border-bottom: 4px solid #b676ff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px -7px 8px 0px;
    z-index: 10;
    position: relative;
}

/* Efecto hover para darle dinamismo */
#howWorks:hover {
    background: linear-gradient(135deg, #240b5e 0%, #320d5e 100%);
    box-shadow: rgba(0, 0, 0, 0.3) 0px -10px 15px 0px;
}

/* Estilo del título */
#howWorks h1 {
    color: #ffffff;
    max-width: 800px;
    font-size: 3rem; /* Tamaño grande para mayor impacto */
    font-weight: 700;
    margin: 0 auto 20px auto; /* Centrado completo del título */
    letter-spacing: 1px; /* Espaciado entre letras */
    text-shadow: 3px 3px 15px rgba(0, 0, 0, 0.6); /* Sombra más notoria */
    text-align: center; /* Asegurar que el título esté centrado */
    opacity: 0; /* Inicialmente invisible para la animación de aparición */
    animation: fadeIn 1.5s ease forwards; /* Animación de aparición */
}

/* Efecto hover en el título */
#howWorks h1:hover {
    transform: scale(1.05); /* Efecto hover de ligero crecimiento */
}



/* Estilo de los párrafos */
#howWorks p {
    color: #ffffff;
    max-width: 700px;
    font-size: 1.2rem;
    line-height: 1.8;
    margin: 0 auto;
    opacity: 0.9;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Efecto hover en los párrafos */
#howWorks p:hover {
    opacity: 1; /* Al hacer hover, el texto se vuelve completamente opaco */
    transform: translateY(-5px); /* Sutil elevación en hover */
}

/* Responsive: Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
    #howWorks {
        padding: 2rem;
    }

    #howWorks h1 {
        font-size: 2.5rem;
    }

    #howWorks p {
        font-size: 1rem;
    }
}
