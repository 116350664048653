@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

body {
  font-family: 'Inter', sans-serif;
  background-color: #f0f2f5;
}

.registro-form {
  max-width: 600px;
  margin: 40px auto;
  padding: 30px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.registro-form:hover {
  transform: scale(1.03);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.registro-form img {
  max-width: 150px;
  margin-bottom: 20px;
}

.registro-form div {
  margin-bottom: 20px;
  position: relative;
}

.registro-form label,
.decision-container p {
  display: block;
  margin-bottom: 8px;
  font-weight: 400; /* Cambiado a 300 */
  color: #666; /* Cambiado a un gris más claro */
  transition: color 0.3s ease;
  text-align: left;
}

.registro-form input,
.registro-form textarea,
.registro-form select { /* Añadido select aquí */
  width: 100%;
  padding: 12px 12px 12px 40px; /* Espacio para el ícono */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  appearance: none; /* Elimina el estilo por defecto del navegador */
  background-color: #fff;
}

.registro-form select {
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%236D58FF" d="M2 0L0 2h4z"/></svg>'); /* Flecha personalizada */
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 10px 10px;
}

.registro-form input:focus,
.registro-form textarea:focus,
.registro-form select:focus { /* Añadido select aquí */
  border-color: #6D58FF;
  box-shadow: 0 0 5px rgba(109, 88, 255, 0.5);
  outline: none;
}

.registro-form button {
  padding: 12px 25px;
  background-color: #362A89;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
}

.registro-form button:hover {
  background-color: #6D58FF;
  transform: translateY(-3px);
}

.registro-form button:active {
  transform: translateY(1px);
}

.registro-form i {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #6D58FF;
  transition: color 0.3s ease;
  pointer-events: none; /* Evita que los íconos interfieran con el clic en los inputs */
}

.registro-form input:focus + i,
.registro-form select:focus + i,
.registro-form textarea:focus + i {
  color: #362A89;
}

@media (max-width: 600px) {
  .registro-form,
  .decision-container,
  .agradecimiento-container {
    padding: 20px;
    margin: 20px auto;
    width: 90%;
  }

  .registro-form input,
  .registro-form textarea,
  .registro-form select {
    padding: 10px 10px 10px 35px;
    font-size: 14px;
  }

  .registro-form label,
  .decision-container p {
    font-size: 14px;
  }

  .registro-form button,
  .decision-container button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .logo {
    max-width: 120px;
  }

  .check-icon {
    font-size: 60px;
  }

  .contador {
    font-size: 2em;
  }

  .registro-form textarea {
    min-height: 100px;
    resize: vertical;
  }

  .registro-form label {
    margin-bottom: 10px;
    display: inline-block;
  }

  .error-message {
    margin: 20px auto;
    max-width: 600px;
    box-sizing: border-box;
  }
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 150px;
}

.decision-container {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 40px auto;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.decision-container:hover {
  transform: scale(1.03);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.check-icon {
  font-size: 80px; /* Tamaño grande */
  color: #28a745; /* Verde */
  margin-bottom: 20px;
}

.decision-container p {
  font-size: 1.2em; /* Texto más pequeño */
  margin-bottom: 20px;
}

.decision-container p strong {
  font-weight: bold; /* Nombre del usuario en negrita */
}

.decision-container button {
  padding: 15px 30px;
  background-color: #362A89;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  margin: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: auto;
  font-size: 1em;
}

.decision-container button:hover {
  background-color: #6D58FF;
  transform: translateY(-3px);
}

.decision-container button:active {
  transform: translateY(1px);
}

.decision-container .highlight-button {
  background-color: #6D58FF; /* Morado */
  font-size: 1.1em; /* Tamaño de fuente más grande */
  font-weight: bold;
}

.decision-container .highlight-button:hover {
  background-color: #362A89; /* Color más oscuro al pasar el ratón */
}

.agradecimiento-container {
  text-align: center;
  padding: 40px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 40px auto;
}

.agradecimiento-container p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.contador {
  font-size: 2.5em;
  font-weight: bold;
  color: #362A89;
}

.error-message {
  color: #d32f2f;
  background-color: #fde8e8;
  border: 1px solid #fbd2d2;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
