/* General Navbar Styles */
nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 2rem;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    position: sticky;
    top: 0;
    z-index: 1000;
    font-family: 'Inter', sans-serif;
    backdrop-filter: blur(0px);
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

.scrolled {
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
}

.nav-logo-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.nav_logo {
    max-height: 40px;
    object-fit: contain;
}

/* Modo móvil */
@media (max-width: 768px) {
    .nav-logo-container {
        justify-content: center;
    }
    
    nav {
        padding: 1rem;
    }
}
